._1dlf4346 {
  background-color: var(--stackflow-plugin-basic-ui-dim-background-color);
}
._1dlf4347 {
  background-color: var(--stackflow-plugin-basic-ui-background-color);
}
._1dlf4348 {
  transition: transform var(--_1dlf4340), opacity var(--_1dlf4340);
}
._1dlf434c {
  transform: translate3d(100%, 0, 0);
}
._1dlf434i {
  opacity: 0;
  z-index: var(--_1dlf4341);
  will-change: opacity;
}
._1q6dj2j0 ._1dlf434i {
  height: 10rem;
  background: linear-gradient(var(--stackflow-plugin-basic-ui-dim-background-color), rgba(0, 0, 0, 0));
}

        ._1dlf4349 ._1dlf434i,
        ._1dlf434a ._1dlf434i
       {
  opacity: 1;
}

        ._1dlf434b ._1dlf434i,
        ._1dlf434c ._1dlf434i
       {
  opacity: 0;
}
._1dlf434j {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: var(--_1dlf4342);
  will-change: transform;
}
._1dlf434j::-webkit-scrollbar {
  display: none;
}
._1q6dj2j1 ._1dlf434j {
  transform: translate3d(100%, 0, 0);
}

          ._1q6dj2j1 ._1dlf4349 ._1dlf434j,
          ._1q6dj2j1 ._1dlf434a ._1dlf434j
         {
  transform: translate3d(0, 0, 0);
}
._1q6dj2j0 ._1dlf434j {
  opacity: 0;
  transform: translate3d(0, 10rem, 0);
}

          ._1q6dj2j0 ._1dlf4349 ._1dlf434j,
          ._1q6dj2j0 ._1dlf434a ._1dlf434j
         {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}
._1dlf434k {
  transition: transform var(--_1dlf4340), opacity var(--_1dlf4340), padding-top var(--stackflow-plugin-basic-ui-app-bar-height-transition-duration);
  padding-top: calc(var(--stackflow-plugin-basic-ui-app-bar-height) + constant(safe-area-inset-top));
  padding-top: calc(var(--stackflow-plugin-basic-ui-app-bar-height) + env(safe-area-inset-top));
}
._1q6dj2j1 ._1dlf434l {
  transform: translate3d(0, 100%, 0);
}
._1dlf434m {
  width: 1.25rem;
  z-index: var(--_1dlf4343);
}
._1dlf434n {
  top: var(--stackflow-plugin-basic-ui-app-bar-height);
  height: calc(100% - var(--stackflow-plugin-basic-ui-app-bar-height));
}