.dhthxq0 {
  width: 100%;
}
.dhthxq1 {
  height: 100%;
}
.dhthxq2 {
  top: 0;
}
.dhthxq3 {
  left: 0;
}
.dhthxq4 {
  position: absolute;
}
.dhthxq5 {
  position: relative;
}
.dhthxq7 {
  display: flex;
}
.dhthxq8 {
  flex: 1;
}
.dhthxq9 {
  align-items: center;
}
.dhthxqa {
  align-items: flex-end;
}
.dhthxqb {
  justify-content: center;
}
.dhthxqc {
  overflow: hidden;
}
.dhthxqd {
  white-space: nowrap;
}
.dhthxqe {
  text-align: center;
}
.dhthxqf {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.dhthxqg {
  box-sizing: border-box;
}
.dhthxqh {
  box-sizing: content-box;
}
.dhthxqi {
  appearance: none;
  border: 0;
  padding: 0;
  background: none;
}